$menuText: #605e5c;
$generalText: #605e5c;
$activeMenu: #1976d2;
$activeMenuText: rgba(0, 0, 0, 0.87);
$menuHoverBgr: #f0f0f0;
$columnBorder: #edebe9;

@mixin centered {
  display: flex;
  justify-content: center;
  padding: 10em 0;
}

.g-container {
  width: 100%;
  max-width: 1200px;
  position: relative;
}

#root, .Portal, .Portal__body, .App, .App__cols, .Table {
  width: 100%;
  height: 100%;
}

.Portal {
  display: flex;
  flex-direction: column;
}

.PortalHeader__bar {
  display: flex;
  justify-content: center;
}

.Portal__body {
  background-color: #fcfcfe;
}

.Portal__body, .App__cols {
  display: flex;
  color: $generalText;
  justify-content: center;
}

.Portal--loading {
  @include centered;
  width: 100%;
  height: 100%;
}

.PortalMenu {
  position: absolute;
  z-index: 1001;
  height: 100%;
  overflow-x: hidden;
  padding: 3px 0 0 3px;
  background-color: #fcfcfe;
  border-right: 1px solid $columnBorder;
  box-shadow: 5px 0 10px -10px rgba(159,160,166,1);
}

.PortalMenu__item {
  border-left: 3px solid transparent;
}

.PortalMenu__item--current {
  color: $activeMenuText;
  border-left-color: $activeMenu;
}

.PortalMenu__item .PortalMenu__button {
  padding-left: 8px;
}

.AppMenu {
  min-width: 200px;
  height: 100%;
  padding: 3px;
}

.AppMenu__item {
  border-left: 3px solid transparent;
}

.AppMenu__item .AppMenu__button {
  padding-left: 22px;
}

.AppMenu__item--current {
  color: $activeMenuText;
  border-left-color: $activeMenu;
}

.Table {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.Table--loading {
  @include centered;
  width: 100%;
}

.TableGrid, .TableEdit__form {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e3;
  border-radius: 5px;
}

.TableGrid__toolbar {
  display: flex;
  width: 100%;
  margin-left: -5.5em;
}

.TableGrid__add {
  position: absolute;
  z-index: 1001;
  margin-top: 1em;
  right: 3em;
}

.ListGrid .TableGrid__add {
  margin-top: 0;
}

.TableGrid__actionButton {
  color: $generalText;
}

.TableEdit__header {
  display: flex;
  align-items: center;
}

.TableEdit__confirm {
  margin-bottom: 3em;
}

.TableEdit__field {
  margin-bottom: 3em
}

.TableEdit__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 3em
}

.TableEdit__header {
  margin-bottom:  1.5rem;
}

.TableEdit__tabs {
  margin-top:  -1.5rem;
  margin-bottom: 3em;
}

.ListGrid {
  margin-top: -1em;
}