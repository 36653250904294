html, body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body, button, input, select, textarea {
  margin: 0;
  font: 12px Arial, sans-serif;
}